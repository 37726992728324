<template>
  <div class="container" data-testid="api-error-page">
    <div class="row">
      <div class="col-sm-12 align-self-center">
        <div class="mx-auto text-center">
          <div class="display-1 text-muted mb-5">
            {{ heading }}
          </div>
          <p class="h4 text-muted font-weight-normal mb-7">
            {{ message }}
          </p>
          <router-link :to="href" class="btn btn-primary">
            <i class="fe fe-arrow-left mr-2" />Go back
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ApiError',
  props: { notAllowedSiteNo: { type: String, default: null } },
  data() {
    return {
      data: JSON.parse(sessionStorage.getItem('error:data')),
      href: sessionStorage.getItem('last:path'),
    }
  },
  computed: {
    message() {
      return this.data?.message || '-'
    },
    heading() {
      return this.data?.statusCode || 'Error'
    },
  },
  created() {
    sessionStorage.removeItem('error:data')
  },
}
</script>

<style lang="scss" scoped>
.container {
  margin-top: 15%;
}
</style>
